import { useEffect, useState } from 'react';
import { useAccount, useSetClientId } from '../hooks/useAccount';

export default function GA4UserProperties() {
	const { billingStatus, basePrice, gaClientId, shop } = useAccount();
	const [foundClientId, setFoundClientId] = useState<string>();

	const { setClientId } = useSetClientId();

	useEffect(() => {
		if (!window.gtag) {
			return;
		}
		window.gtag('config', 'G-RT14WDF866', {
			user_id: shop
		});
		window.gtag('set', 'user_properties', {
			paidUserValue: billingStatus === 'active' ? basePrice / 100 : 0
		});
	}, [billingStatus, basePrice, shop]);

	useEffect(() => {
		if (!window.gtag) {
			return;
		}
		window.gtag('get', 'G-RT14WDF866', 'client_id', (clientId: string) => setFoundClientId(clientId));
	}, []);

	useEffect(() => {
		if (foundClientId && gaClientId !== foundClientId) {
			setClientId(foundClientId);
		}
	}, [gaClientId, foundClientId, setClientId]);

	return null;
}
