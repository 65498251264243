import { LoadingScreen, useAppBridgeRouter } from '@segunosoftware/equinox';
import { Loading, Provider } from '@shopify/app-bridge-react';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import GA4UserProperties from './components/GA4UserProperties';
import I18nProvider from './components/I18nProvider';
import ProtectedRoute from './components/ProtectedRoute';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import { useHostParam } from './hooks/useHostParam';
import { CreateDiscountSet, DiscountSetList, EditDiscountSet, EditPriceRule, OAuth, Welcome } from './utils/lazy-load';

const SHOPIFY_APP_KEY = import.meta.env.VITE_SHOPIFY_KEY;

export default function App() {
	const location = useLocation();
	const host = useHostParam() ?? '';
	const router = useAppBridgeRouter();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	const config = useMemo(
		() => ({
			apiKey: SHOPIFY_APP_KEY,
			host,
			forceRedirect: !isOAuthPath
		}),
		[host, isOAuthPath]
	);
	return (
		<Provider key={isOAuthPath.toString()} config={config} router={router}>
			<SentryWrapper>
				<Suspense fallback={<LoadingScreen delay={500} />}>
					<Routes>
						<Route path="/oauth/*" element={<OAuth />} />
						<Route path="/external/*" element={<ExternalLinks />} />
						<Route path="/*" element={<ProtectedRoute element={<AppEmbed />} />} />
					</Routes>
				</Suspense>
			</SentryWrapper>
		</Provider>
	);
}

function AppEmbed() {
	const isFetching = useIsFetching();
	const calculatedLoading = isFetching > 0;
	return (
		<I18nProvider>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="new" element={<CreateDiscountSet />} />
					<Route path="edit/:discountSetId" element={<EditDiscountSet />} />
					<Route path="edit-price-rule/:priceRuleId" element={<EditPriceRule />} />
					<Route path="welcome" element={<Welcome />} />
					<Route path="" element={<DiscountSetList />} />
				</Routes>
			</Suspense>
			<SupportChat />
			<GA4UserProperties />
			{calculatedLoading && <Loading />}
		</I18nProvider>
	);
}

function ExternalLinks() {
	const [searchParams] = useSearchParams();

	return (
		<Routes>
			<Route path="new" element={<Navigate to="/new" />} />
			<Route path="edit" element={<Navigate to={`/edit-price-rule/${searchParams.get('id')}`} />} />
		</Routes>
	);
}
